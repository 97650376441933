<template>
  <div>
    <div class="row col-12">
      <CCard class="col-6">
        <CCardBody>
          <div class="row justify-content-between">
            <h6>Print Rekapitulasi Pengaduan Berdasarkan Tahun</h6>
            <div class="col-10">
              <div class="row mb-3">
                <p>Pilih Tahun :  &nbsp; &nbsp;</p>
                <select
                  class="custom-select"
                  style="max-width: 150px"
                  id="inputGroupSelect01"
                  v-model="params1.tahun"
                >
                <option selected value="2023">2023</option>
                    <option value="2022">2022</option>
                    <option value="2021">2021</option>
                </select>
              </div>
              <div class="row mb-3">
                <button class="btn btn-primary" @click="handlePrintReport4a">Print Report</button>
              </div>
              <!-- <div class="row mb-3">
                <button class="btn btn-primary" @click="handlePrintReport4b">Print Report Excel</button>
              </div> -->
            </div>
          </div>
        </CCardBody>
      </CCard>
      <CCard class="col-6">
        <CCardBody>
          <template>
            <div class="row justify-content-between">
              <h6>Print Pemakaian Barang Pemeliharaan Tim</h6>
              <div class="col-10">
                <div class="row mb-3">
                  <p>Pilih Bulan :  &nbsp; &nbsp;</p>
                  <select
                    class="custom-select"
                    style="max-width: 150px"
                    id="inputGroupSelect01"
                    v-model="praramsTeam.bulan"
                  >
                  <option selected value="1">Januari</option>
                    <option value="2">Februari</option>
                    <option value="3">Maret</option>
                    <option value="4">April</option>
                    <option value="5">Mei</option>
                    <option value="6">Juni</option>
                    <option value="7">Juli</option>
                    <option value="8">Agustus</option>
                    <option value="9">September</option>
                    <option value="10">Oktober</option>
                    <option value="11">November</option>
                    <option value="12">Desember</option>
                  </select>
                </div>
                <div class="row mb-3">
                  <p>Pilih Tahun :  &nbsp; &nbsp;</p>
                  <select
                    class="custom-select"
                    style="max-width: 150px"
                    id="inputGroupSelect01"
                    v-model="praramsTeam.tahun"
                  >
                  <option selected value="2023">2023</option>
                    <option value="2022">2022</option>
                    <option value="2021">2021</option>
                  </select>
                </div>
                <div class="row mb-3">
                  <p>Pilih Tim :  &nbsp; &nbsp;</p>
                  <select
                    class="custom-select"
                    style="max-width: 150px"
                    id="inputGroupSelect01"
                    v-model="praramsTeam.team_id"
                  >
                  <option selected value=""></option>
                  <option v-for="team in dataTeam" :key="team.id" :value="team.id">{{ team.name }}</option>
                  </select>
                </div>
                <div class="row mb-3">
                  <button class="btn btn-primary" @click="handlePrintReport2a">Print Report</button>
                </div>
                <!-- <div class="row mb-3">
                  <button class="btn btn-primary" @click="handlePrintReport2b">Print Report Excel</button>
                </div> -->
              </div>
            </div>
          </template>
        </CCardBody>
      </CCard>
    </div>
    <div class="row col-12">
      <CCard class="col-6">
        <CCardBody>
          <template>
            <div class="row justify-content-between">
                <h6>Print Rekapitulasi Pemakaian Barang Pemeliharaan</h6>
                <div class="col-10">
                  <div class="row mb-3">
                    <p>Pilih Bulan :  &nbsp; &nbsp;</p>
                    <select
                      class="custom-select"
                      style="max-width: 150px"
                      id="inputGroupSelect01"
                      v-model="params3.bulan"
                    >
                    <option selected value="1">Januari</option>
                      <option value="2">Februari</option>
                      <option value="3">Maret</option>
                      <option value="4">April</option>
                      <option value="5">Mei</option>
                      <option value="6">Juni</option>
                      <option value="7">Juli</option>
                      <option value="8">Agustus</option>
                      <option value="9">September</option>
                      <option value="10">Oktober</option>
                      <option value="11">November</option>
                      <option value="12">Desember</option>
                    </select>
                  </div>
                  <div class="row mb-3">
                    <p>Pilih Tahun :  &nbsp; &nbsp;</p>
                    <select
                      class="custom-select"
                      style="max-width: 150px"
                      id="inputGroupSelect01"
                      v-model="params3.tahun"
                    >
                    <option selected value="2023">2023</option>
                      <option value="2022">2022</option>
                      <option value="2021">2021</option>
                    </select>
                  </div>
                  <div class="row mb-3">
                    <button class="btn btn-primary" @click="handlePrintReport3">Print Report</button>
                  </div>
                </div>
              </div>
          </template>
        </CCardBody>
      </CCard>
      <CCard class="col-6">
        <CCardBody>
          <template>
            <div class="row justify-content-between">
              <h6>Print Kegiatan Pemeliharaan PJU Berdasarkan Tahun</h6>
              <div class="col-10">
                <div class="row mb-3">
                  <p>Pilih Tahun :  &nbsp; &nbsp;</p>
                  <select
                    class="custom-select"
                    style="max-width: 150px"
                    id="inputGroupSelect01"
                    v-model="params4.tahun"
                  >
                  <option selected value="2023">2023</option>
                      <option value="2022">2022</option>
                      <option value="2021">2021</option>
                  </select>
                </div>
                <div class="row mb-3">
                  <button class="btn btn-primary" @click="handlePrintReport5">Print Report</button>
                </div>
              </div>
            </div>
          </template>
        </CCardBody>
      </CCard>
    </div>
    <div class="row col-12">
      <CCard class="col-6">
        <CCardBody>
          <template>
            <div class="row justify-content-between">
              <h6>Print Rekapitulasi Kegiatan Pemeliharaan Berdasarkan Tahun</h6>
              <div class="col-10">
                <div class="row mb-3">
                  <p>Pilih Tahun :  &nbsp; &nbsp;</p>
                  <select
                    class="custom-select"
                    style="max-width: 150px"
                    id="inputGroupSelect01"
                    v-model="params5.tahun"
                  >
                  <option selected value="2023">2023</option>
                      <option value="2022">2022</option>
                      <option value="2021">2021</option>
                  </select>
                </div>
                <div class="row mb-3">
                  <button class="btn btn-primary" @click="handlePrintReport6">Print Report</button>
                </div>
              </div>
            </div>
          </template>
        </CCardBody>
      </CCard>
      <!-- <CCard class="col-6">
        <CCardBody>
          <template>
            <h4>FORM PENGAMBILAN BARANG</h4>
          </template>
        </CCardBody>
      </CCard> -->
      </div>
  </div>
</template>

<script>
import * as data from "../../model/role";
import { uploadImage } from "@/utils/fileUpload";
import FileSaver from "file-saver";

export default {
  data() {
    return {
      params1:{
        tahun : "",
      },
      params2: {
        sorttype: "asc",
        sortby: "id",
        row: 50,
        page: 1,
        keyword: "",
      },
      dataTeam: [],
      praramsTeam: {
        bulan: '',
        tahun: '',
        team_id: '',
      },
      params3: {
        bulan: "",
        tahun : "",
      },
      params4:{
        tahun : "",
      },
      params5:{
        tahun : "",
      },
    };
  },
  methods: {
    getDataTeam() {
      var loading = this.$loading.show()
      this.$store
        .dispatch("team/getTeam", this.params2)
        .then((resp) => {
          this.dataTeam = resp.data.data
          // console.log(this.dataTeam)

          // khusus untuk checkbox
          this.selectedItems = []
          this.items.forEach((element) => {
            if (this.isSelectedAll) {
              element.select = true
              this.selectedItems.push(element.id)
            } else {
              element.select = false
            }
          })
          loading.hide()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    printReport1a(item) {
      let route = this.$router.resolve({
        name: "laporan-pemeliharran-pdf",
        query: { tahun: item },
      });
      window.open(route.href, "_blank");
    },
    printReport1b(item) {
      var loading = this.$loading.show();
      this.$store.dispatch("report/getReportPengaduanExcel", {tahun: item}).then((resp) => {
        loading.hide();
        const blob = new Blob([resp], { type: 'application/octet-stream' });
        FileSaver.saveAs(blob, 'RekapitulasiLaporanPemeliharaan.csv');
      }).catch(e => {
        this.$toast.error(e);
        loading.hide();
      });
    },
    printReport2a(bulan, tahun, team_id) {
      let route = this.$router.resolve({
        name: "laporan-item-usage-team-pdf",
        query: { tahun: tahun, bulan: bulan, team: team_id },
      });
      window.open(route.href, "_blank");
    },
    printReport2b(bulan, tahun, team_id) {
      var loading = this.$loading.show();
      this.$store.dispatch("report/getReportPengaduanExcel", {tahun: tahun, bulan: bulan, team: team_id}).then((resp) => {
        loading.hide();
        const blob = new Blob([resp], { type: 'application/octet-stream' });
        FileSaver.saveAs(blob, 'RekapitulasiLaporanPemeliharaan.csv');
      }).catch(e => {
        this.$toast.error(e);
        loading.hide();
      });
    },
    printReport3(bulan, tahun) {
      let route = this.$router.resolve({
        name: "laporan-team-item-usage-pdf",
        query: { tahun: tahun, bulan: bulan},
      });
      window.open(route.href, "_blank");
    },
    printReport4(item) {
      let route = this.$router.resolve({
        name: "pemeliharaan-pju-tahunan-pdf",
        query: { tahun: item },
      });
      window.open(route.href, "_blank");
    },
    printReport5(item) {
      let route = this.$router.resolve({
        name: "rekap-kegiatan-pemeliharran-pdf",
        query: { tahun: item},
      });
      window.open(route.href, "_blank");
    },
    handlePrintReport2a() {
      this.printReport2a(this.praramsTeam.bulan, this.praramsTeam.tahun, this.praramsTeam.team_id);
    },
    handlePrintReport2b() {
      this.printReport2b(this.praramsTeam.bulan, this.praramsTeam.tahun, this.praramsTeam.team_id);
    },
    handlePrintReport3() {
      this.printReport3(this.params3.bulan, this.params3.tahun);
    },
    handlePrintReport4a() {
      this.printReport1a(this.params1.tahun);
    },
    handlePrintReport4b() {
      this.printReport1b(this.params1.tahun);
    },
    handlePrintReport5() {
      this.printReport4(this.params4.tahun);
    },
    handlePrintReport6() {
      this.printReport5(this.params5.tahun);
    },
  },
  mounted() {
    this.getDataTeam();
  },

  created() {
    this.fields = data.fields
  },
};
</script>